.ant-modal.content-modal {
  width: 75% !important;
  top: 1rem;

  .tutorialIframe{
    width: 100%;
    height: 76vh;
  }
  
  .tutorials-list{
    padding: 0;
  }

  .ant-modal-body {
    padding: 3rem;
  }

  .ant-carousel {
    .slick-dots {
      bottom: -2.5rem;
    }

    .slick-dots {
      li {
        button {
          background-color: #09d96d !important;
        }
      }
    }
  }

  .slick-arrow {
    width: 2.5rem;
    height: 2.5rem;
    top: 49%;

    &.slick-next {
      right: -2.5rem;
    }

    &.slick-prev {
      left: -2.5rem;
    }
  }
}
