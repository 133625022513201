@import '../../../styles/standard.scss';

.tyc {
  font-family: Roboto;

  .tyc-card-padding {
    padding: 4rem;

    .wrapper {
      max-height: 50vh;
      padding: 2rem;
      overflow-y: scroll;
      p {
        font-size: 1rem;
        font-weight: 500;
        text-align: justify;
      }
    }
    .btn-accept {
      margin: 2rem;
      width: 85%;
    }
  }

  .tyc-top-margin {
    margin-top: 2rem;
  }
}
