@import '../../../styles/standard.scss';

.help-content {
  font-family: Roboto;

  .help-title {
    margin: 1.5rem;
    color: $font-color-light;
    text-align: center;
  }

  .help-top-margin {
    margin-top: 2rem;
  }

  .help-links-lmargin {
    margin-left: 1.5rem;
  }

  .contact-item {
    text-align: center;
    .help-contact-image-wrapper {
      margin: 0 auto 2rem auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      height: 5rem;
      padding: 0.5rem;

      .help-contact-image {
        width: 100%;
      }
    }
    .text {
      margin: 0 auto;
      font-weight: 400;
      font-size: 1rem;
      color: #828282;
    }
    .data {
      margin: 0 auto;
      font-size: 0.9rem;
      font-weight: bold;
      color: #828282;
    }
  }
  .ant-card.ant-card-bordered.card-radius.card-shadow{
    height: 100%;
  }
}
