@import "../../../styles/variables.scss";

.reports-style {
  padding: 2% 6.5%;

  .reports-container {
    width: 100%;
    position: relative;
    padding: 2% 2%;
  }

  .ant-card-body {
    padding: 0;
  }

  .header-container {
    margin: auto;
    width: 65%;

    .title {
      margin-top: 0.1rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: $secondary-green;
    }

    .filters-container {
      .ant-form-item-label>label {
        font-size: 1.1rem !important;
        color: #828282;
      }
    }
  }

  .table-container {
    margin: auto;

    .bill-selection {
      margin-top: 3rem;

      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }

      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-table-thead>tr>th {
      padding: 0.5rem;
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .btn-excel {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }

    .export {
      text-align: right;
    }

    .ant-table {
      font-size: 1rem;

      .ant-table-column-sorters {
        padding: 2px;
      }

      .error-column {
        min-width: 350px;
      }
    }

    .format-number {
      white-space: nowrap;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-select,
  .ant-select-selector,
  .ant-form-item-control-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    margin-top: 2px;
    width: 100%;
    border-radius: 5px;
    height: 1.8rem;
    text-align: left;
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;

    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-tabs-tab {
    line-height: 4rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: $secondary-blue;
  }

  .ant-tabs-bar {
    outline: 0.55rem solid gray !important;
  }

  .ant-pagination {
    font-size: 1rem;

    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }

      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table-container {
      overflow: auto;
    }
  }

}
