/** Colors*/

$primary-green: #38d380;
$secondary-green: #09d96d;
$primary-blue: #3399ff;
$secondary-blue: #00c1ed;
$menu-active: #176d8e;
$background-light: white;
$background-medium: #f5f5f5;
$border-color: #cccccc;

$red-pdf: #c50606;
/** Fonts */
$font-color-light: #828282;
$font-color-title: #4f4f4f;
$font-family: "Roboto";

/**Shadoyw*/
$standar-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.75);

$card-radius: 9px;
$button-radius: 10px;
