@import './standard.scss';

.login-layout-style {
  width: 100vw;
  height: 100vh;
  .ant-card-body {
    padding: 0;
  }
  .login-card {
    box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
    background-color: #f6f6f6;
    max-width: 50rem;
    min-width: 50rem;
  }
}
