@import "../../../../styles/variables.scss";

.bulkload-style {
  .header-container {
    margin: auto;
    width: 100%;

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }
  }
  .ant-tabs-tab {
    padding: 1rem 2rem;
    line-height: 5rem;
    font-weight: bold;
    font-size: 1.3rem;
    color: $secondary-blue;
  }
  .ant-tabs-ink-bar {
    height: 1rem !important;
    background-color: $secondary-blue;
  }

  .ant-tabs-bar {
    outline: 1rem solid gray !important;
    .ant-tabs-nav-wrap {
      margin-bottom: -2px;
    }
  }

  .content-container {
    .file-upload {
      margin: auto;
      width: 65%;
      margin-bottom: 3rem;
      p.file-upload-message,
      p.ant-upload-text {
        font-size: 1.2rem;
        color: $font-color-light;
        margin-bottom: 0.5rem;
      }
      p.file-upload-message {
        text-align: left;
      }
      .ant-upload-drag-icon {
        color: $secondary-blue;
      }
      .ant-upload-text-icon {
        text-align: left;
      }
      .ant-upload-list-item-name {
        text-align: left;
      }
      .ant-input,
      .ant-picker,
      .ant-select {
        width: 50%;
        border-radius: 5px;
        line-height: 2.25rem;
        margin: 1rem 0;
      }

      .label {
        text-align: left;
      }

      .select-wrapper {
        text-align: left;
      }
    }
    .template-download {
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        font-size: 1.2rem;
        margin: 0 1rem;
      }
    }

    .detail-container {
      max-width: 800px;
      margin: auto;

      .form-label {
        margin: 0 0.5rem;
      }
      .module-selector-select {
        width: 100%;
      }
      .task-button-component {
        background-color: #09d96d;
        color: white;
        font-weight: 500;
        border: none;
        border-radius: 5px;
      }
    }
  }
  .table-wrapper {
    max-width: 100%;
    overflow-x: auto;
    .icon-size {
      font-size: 1.5rem;
      cursor: pointer;

      &.color-alert {
        color: #ff6a00;
      }
      &.color-error {
        color: #ff1100;
      }
      &.color-success {
        color: #4ba138;
      }
    }
  }
  .btn {
    margin-top: 1rem;
    width: 10rem;
    height: 2.5rem;
    border-radius: 0.6rem;
    color: white;
    &.btn-blue {
      background-color: $primary-blue;
    }
    &.btn-green {
      background-color: $primary-green;
    }
  }
}
