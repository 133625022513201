@import '../../../styles/standard.scss';

.affitoken-style {
  padding: 2% 16%;
  .standard-steps.steps-container {
    padding: 0 12rem;
  }

  .ant-divider-horizontal {
    height: 10px;
    background-color: #bdbdbd;
    width: 100%;
  }
  .form-divider {
    height: 1px;
    margin-bottom: 0;
    background-color: #dddddd;
  }

  .configure-form,
  .send-form {
    background-color: #f6f6f6;
    padding: 5%;
    margin-top: 1rem;
    p {
      margin: auto;
      font-size: 1.2rem;
      font-weight: 600;
      color: #828282;
    }
    .ant-form {
      .ant-col .ant-form-item-label {
        padding: 0;
        bottom: 2px;
        label {
          color: $font-color-light;
          font-weight: 600;
          font-size: 1rem;
        }
      }

      .ant-form-item-label > label.ant-form-item-required::before {
        color: $font-color-light;
        margin: 0;
        content: '';
      }

      .ant-form-item-label > label.ant-form-item-required::after {
        color: $font-color-light;
        margin: 0;
        display: inline-block;
        margin-left: 4px;
        margin-top: 7px;
        font-size: 1.1rem;
        content: '*';
      }

      .ant-form-item-control-input-content {
        input,
        .ant-select-selector {
          border-radius: 0.3rem;
        }

        .ant-select-selector,
        .ant-picker,
        .ant-input {
          border-color: $border-color;
        }
      }
    }
  }

  .submit-container,
  .configure-form {
    margin-top: 2rem;
    text-align: center;
    .form-update-send{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: flex-start;

    }
    .form-btn {
      width: 30%;
      background-color: #0ad86c;
      border: none;
      color: white;
      font-weight: 500;
    }
    .affitoken-alert {
      margin: 1rem;
    }
  }

  .configure-style,
  .send-style {
    padding: 0 3.3rem;
    color: $font-color-light;
    .title-container {
      .title {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .description {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
