@import '../../../styles/standard.scss';

.content-margin-2 {
  font-family: Roboto;
  color: $font-color-light;

  .dash-card-margin {
    & .ant-card-body {
      padding: 1rem;
      height: 175px;
    }
  }

  .dash-greeting {
    font-size: 1.25rem;
    font-weight: 400;
    white-space: nowrap;
    .dash-greeting-highlight {
      color: $primary-blue;
    }
    p {
      margin: 0;
    }
  }

  .dash-text-center {
    text-align: center;
  }

  .dash-how-much {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem;
    .ant-input-number {
      width: 100%;
    }
    button {
      margin-top: 0.5rem;
    }
  }

  .dashNewInvoices {
    font-size: 3rem;
    margin-bottom: 0;
  }

  .dash-bottom-button1 {
    height: 5rem;
    background-color: $menu-active;
    border-color: $menu-active;
    border-radius: 0.5rem;
    font-size: 1.5rem;
  }

  .dash-bottom-button2 {
    height: 5rem;
    background-color: $primary-green;
    border-color: $primary-green;
    border-radius: 0.5rem;
    font-size: 1.5rem;
  }

  .dash-button-icon {
    font-size: 2.5rem;
  }
}

.dash-modal {
  & .ant-modal-content {
    border-radius: 1rem;

    .ant-modal-body {
      padding: 0;
      margin-right: 1rem;
    }

    .ant-input-number {
      width: 100%;
    }

    .dash-modal-gray-col {
      background-color: $background-medium;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      p {
        margin: 1rem;
        text-align: center;
        font-size: 1rem;
      }
    }
  }
  .for-total {
    font-size: 1rem;
  }

  .dash-modal-flexcolumn {
    flex-direction: column;
  }

  .dash-modal-height1 {
    height: 11rem;
  }
  .dash-modal-height2 {
    height: 6.5rem;
  }
  .dash-modal-height3 {
    height: 11rem;
  }
  .dash-margin-b {
    margin-bottom: 1.5rem;
  }

  .dash-pop-title-color {
    color: $font-color-light;
  }

  .dash-pop-icon-center {
    text-align: center;
  }

  .dash-pop-icon-size {
    //text-align: center;
    font-size: 1.5rem;

    &.agree-color-prim {
      color: $primary-blue;
    }
  }

  .ant-modal-close {
    top: -12px;
    right: -12px;
  }
}

.dash-modal-pop-width {
  width: 12rem;
}

.dash-card-center {
  display: flex;
  justify-content: center;
}

.dash-mini-card {
  height: 175px;
  width: 100%;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: white;
  padding: 2rem 0.2rem;

  &.bkg-green {
    background-image: url(../../../assets/img/boxgreen.png);
    background-repeat: no-repeat;
    // border: 1px solid red;
    justify-content: center;
    background-position: center;
  }

  &.bkg-gray {
    background-image: url(../../../assets/img/boxgray.png);
    background-repeat: no-repeat;
    // border: 1px solid red;
    background-position: center;
  }
  &.bkg-purple {
    background-image: url(../../../assets/img/boxpurple.png);
    background-repeat: no-repeat;
    // border: 1px solid red;
    background-position: center;
    p.text-droped {
      margin-top: 2.5rem;
    }
  }

  &.bkg-blue {
    background-image: url(../../../assets/img/boxblue.png);
    background-repeat: no-repeat;
    // border: 1px solid red;
    background-position: center;
  }

  p.text-no-wrap {
    white-space: nowrap;
  }

  p.text-droped {
    margin-top: 3rem;
  }
}

.dash-card-margin-top {
  margin-top: 1rem;
}

.multiCardsRow {
  .dash-card-margin-top {
    max-height: 23rem;
    height: 23rem;
  }
}

.dash-pop-padding {
  padding: 1rem 0;
}

.dash-pop-p-center {
  p {
    padding: 0 0.5rem 0 1rem;
    font-size: 1rem;
    margin-bottom: 1.15rem;
  }
  text-align: center;
  margin-bottom: 1.2rem;
}
.linechart-container {
  width: 100%;
  height: 15rem;
}

.all-height {
  min-height: 100vh;
}


//Solo para el contador temporal
.radianContainer {
  align-items: center;
  background-color: white;
  display: flex;
  font-family: -apple-system, 
    BlinkMacSystemFont, 
    "Segoe UI", 
    Roboto, 
    Oxygen-Sans, 
    Ubuntu, 
    Cantarell, 
    "Helvetica Neue",
    sans-serif;
    color: #036c8d;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
    inline-size: -webkit-fill-available;
    border-radius: 1rem;
    font-size: smaller;
    justify-content: center;
}

#radianCountdown {
  display: inline-block;
  // font-size: 1.5em;
  font: message-box;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

#radianImage {
  display: flex;
}

#days, #minutes, #hours, #seconds {
  display: block;
  font-size: 4.5rem;
}
