@import "../../../../styles/variables.scss";

.suppliers-style {
    padding: 2% 6.5%;

    .ant-tabs-tab {
        line-height: 4rem;
        font-weight: bold;
        font-size: 1.2rem;
        color: $secondary-blue;
    }

    .ant-tabs-ink-bar {
        height: 0.5rem !important;
        background-color: $secondary-blue;
        bottom: 7px;
    }

    .ant-tabs-nav-container {
        margin-bottom: -1rem;
    }

    .ant-tabs-bar {
        outline: 0.55rem solid gray;
    }

    .header-container {
        margin: auto;
        width: 65%;

        .title {
            margin-top: 5rem;
            font-size: 1.3rem;
            font-weight: 600;
            text-align: left;
        }

        .form-btn {
            width: 100%;
            border: none;
            height: 1.8rem;
            line-height: 1rem;
            background-color: $secondary-green;
            top: 2.2rem;
        }

        .filters-container {
            .ant-form-item-label>label {
                font-size: 1.1rem !important;
                color: #828282;
            }
        }
    }

    .ptittle {
        margin-top: 4rem;
        margin-bottom: 2rem;
        font-size: 1.3rem;
        text-align: left;
    }

    .ant-input,
    .ant-picker,
    .ant-select {
        width: 100%;
        border-radius: 5px;
        height: 2.25rem !important;
    }

    .ant-select-selector {
        border-radius: 0.5rem;
    }

    .check {

        .check-container {
            padding-top: 0.6rem;
            padding-left: 1.9rem;
        }

        .ant-checkbox-indeterminate {
            .ant-checkbox-inner::after {
                width: 60%;
                height: 60%;
                top: 50%;
                left: 50%;
                background-color: #1dcf77;
            }
        }

        .ant-checkbox-inner {
            width: 26px;
            height: 26px;
            border: none;
            background-color: #d3d3d3;
            border-radius: 0.2rem;

            &::after {
                width: 9px;
                height: 20px;
                top: 46%;
                left: 22%;
                border: 3px solid #1dcf77;
                border-top: 0;
                border-left: 0;
            }
        }

        .ant-checkbox-checked::after {
            border: 1px solid #1dcf77;
        }

        span {
            font-size: 1.2rem;
        }
    }
}
