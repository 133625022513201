@import '../../../styles/standard.scss';
@import '../../../styles/variables.scss';

.data-person-style {

  text-align: left;

  .ant-tabs-nav-container {
    line-height: 5;
    color: $secondary-blue;
    font-size: 1rem;

    .ant-tabs-nav .ant-tabs-tab-active {
      color: $secondary-blue;
      margin: 0 27px 0 0;
      font-weight: 600;
    }

    ::after {
      width: 100%;
      background-color: $font-color-light;
      height: 10px;
      margin-top: -11px;
    }

    .ant-tabs-ink-bar {
      height: 9px;
      background-color: $secondary-blue;
    }
  }

  .welcome-message {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .subtitle {
      color: #858282;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }

  .ant-form-item-control-input-content {

    input,
    .ant-select-selector,
    .ant-input-number {
      border-radius: 0.3rem;
    }

    .ant-select-selector,
    .ant-picker,
    .ant-input {
      border-color: $border-color;
    }
  }



  .form-btn {
    width: 100%;
    border: none;
    background-color: $secondary-green;
  }
}

.person-style {

  /*  */

  /*  .ant-form {
    .ant-col .ant-form-item-label {
      padding: 0;
      label {
        color: $font-color-light;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .ant-form-item-label > label.ant-form-item-required::before {
      content: '*';
      color: $font-color-light;
    }

   
  } */
}

.affitoken-modal {
  width: 50% !important;
  min-width: 800px;

  .ant-modal-body {
    width: 80%;
    margin: auto;
  }
}
