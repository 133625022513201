@import '../../../../styles/standard.scss';

.assisted-style {
  .ant-spin-nested-loading > div > .ant-spin {
    height: 90vh;
    max-height: none;
  }
  .title-container {
    margin-top: 2rem;
    .title {
      display: inline-block;
      color: $font-color-title;
      font-size: 1rem;
      font-weight: 700;
    }

    .value {
      display: inline-block;
      color: $font-color-title;
      font-size: 1.4rem;
    }
  }

  .client-container {
    margin-top: 1.6rem;
    padding: 2%;

    .client {
      color: $secondary-green;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .bills-number {
      font-size: 1rem;
      font-weight: 700;
      text-align: right;
      margin-top: 0.4rem;

      span {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;

        &.green {
          color: $secondary-green;
        }

        &.blue {
          color: $primary-blue;
        }
      }
    }
  }

  .detail-container {
    text-align: center;
    margin-bottom: 0.5rem;
    button {
      border: none;
      box-shadow: none;
      color: #888888;
      &:after {
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
      }
    }
  }

  .disabled-investor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ccc6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 2;
    font-weight: bold;
    p {
      border-radius: 1rem;
      padding: 0.5rem;
      background-color: #cccc;
      margin-top: 2.5rem;
      font-size: 1rem;
    }
    .detail-container {
      position: absolute;
      bottom: 0;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      border-radius: 1rem;
      background-color: #cccc;
      button {
        border: none;
        box-shadow: none;
        color: #1890ff;
        background-color: transparent;
        &:after {
          -webkit-animation: none !important;
          -moz-animation: none !important;
          -o-animation: none !important;
          -ms-animation: none !important;
          animation: none !important;
        }
      }
    }
  }
}

.assisted-modal {
  .margin-top {
    margin-top: 1rem;
  }
  .switch-container {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-right: 0.9rem;
    .ant-switch {
      height: 1.6rem;
      background-color: $secondary-green;
      box-shadow: inset 0 0 5px #2c6195;
      border: #128649 1px solid;
      .ant-switch-inner {
        font-weight: 600;
        color: #007a1e;
        padding-bottom: 0.1rem;
        padding-left: 1.7rem;
        padding-right: 0.5rem;
      }
      &::after {
        width: 22px;
        height: 22px;
        top: 1px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
    }
    .ant-switch-checked {
      background-color: #3299ff;
      border: #27629a 1px solid;
      .ant-switch-inner {
        color: #004198;
      }
    }
  }
  .ant-modal-header {
    background-color: lightgray;
  }
  .ant-table-wrapper {
    overflow: auto;
  }

  .ant-table-row {
    text-align: center;
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th {
    padding: 16px 0;
  }

  .ant-table-tbody {
    background-color: #f0f0f0;
    text-align: center;
    font-weight: 600;
    tr:nth-child(even) {
      background-color: white;
    }

    .offer-value {
      color: $secondary-green;
      font-weight: 500;
    }
  }

  .ant-table-cell {
    text-align: center;
  }
}
