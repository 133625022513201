@import "styles/fonts/stylesheet.css";

body {
  font-size: 16px;
  width: 100%;
  min-width: 1024px;
  font-family: "Roboto-Regular" !important;
}

.ant-table {
  .ant-table-row {
    .ant-table-cell {
      span {
        white-space: nowrap;
      }
    }
  }
}
