@import "../../../../../styles/variables.scss";
@import "../../../../../styles/standard.scss";

.bills-events-details-style {

    .sub-title {
        font-size: 1.3rem;
        text-align: left;
    }

    .btn-agregar {
        width: 100%;
        min-height: 4rem;
        max-width: 12rem;
        padding: 0.5rem;
        color: #1890ff;
        font-weight: 500;
        border: #1890ff 3px solid;
        border-radius: 5px;
        white-space: normal;

        &:hover {
            opacity: 0.5;
        }
    }

    .btn-excel {
        background-color: #09d96d;
        color: white;
        font-weight: 500;
        border: none;
        border-radius: 5px;

        &:hover {
            opacity: 0.5;
        }
    }

    /* .table-container {
        margin: auto;

        .bill-selection {
            margin-top: 3rem;

            .select-label {
                text-align: left;
                font-size: 1rem;
                display: block;
                margin: 0;
                padding: 0;
            }

            .select-container {
                display: block;
                margin: 0;
                padding: 0;
            }
        }

        .ant-table-thead>tr>th {
            padding: 0.5rem;
        }

        .ant-table-tbody>tr>td>.ant-btn {
            border-radius: 0.5rem;
        }

        .ant-select-selector {
            border-radius: 0.5rem;
        }

        .ant-table {
            font-size: 1rem;

            .ant-table-column-sorters {
                padding: 2px;
            }
        }

        .format-number {
            white-space: nowrap;
        }
    } */

}
