@import '../../../styles/standard.scss';

.content-margin-2 {
  font-family: Roboto;
  color: $font-color-light;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-button {
    display: none;
    border: none;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 25px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #309bfd;
  }

  .welcome-message {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    .title {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }
    .subtitle {
      color: #858282;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }

  .dash-pop-icon-size {
    font-size: 1.5rem;
  }

  .card-title {
    font-size: 1.5rem !important;
    color: $font-color-light;
    font-weight: 400;
  }

  .card-text {
    font-size: 1rem !important;
    color: $font-color-light;
    font-weight: 400;
  }

  .filters-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ant-select {
      width: 100%;
    }

    .ant-picker {
      width: 100%;
    }
  }

  .top-margin {
    margin-top: 2rem;
  }

  .top-margin-1 {
    margin-top: 1rem;
  }

  .multi-cards-row {
    display: flex;
    .radius {
      height: 20rem;
    }
  }

  .radius {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
  }

  .query-buttons {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }

  .ant-table-wrapper {
    width: 100%;
    margin: auto;
    overflow: auto;
    .ant-table-thead {
      th {
        font-size: 1rem;
        text-align: center;
      }
    }

    .ant-table-tbody {
      td {
        white-space: nowrap;
        font-size: 1rem;
        text-align: center;
      }
    }

    .info {
      color: #00c1ed;
      transition: 0.2s;
      svg {
        width: 24px;
        height: 24px;
      }
      &:hover {
        transition: 0.2s;
        transform: scale(1.1);
      }
    }

    .ant-table-container{
      overflow: auto;
    }
    
  }

  .select-container {
    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-radius: 0.5rem;
      }
    }
    .ant-picker {
      border-radius: 0.5rem;
      height: 1.5rem;
    }
    .ant-input-affix-wrapper {
      border-radius: 0.5rem;
      height: 1.5rem;
    }
  }

  .action-btn-container {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-start;
    .btn-prev {
      background: #00c1ed;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      width: 10rem;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .btn-excel {
    background-color: #09d96d;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    &:hover {
      opacity: 0.5;
    }
  }

  .linechart,
  .doughnutchart {
    max-height: 12rem;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    height: 90vh;
    max-height: none;
  }
}

.report-card {
  margin-top: 1rem;
}

.right-columns {
  text-align: right;
}
