@import "../../../../styles/variables.scss";

.consultation-style {
  padding: 2% 6.5%;

  .header-container {
    margin: auto;
    width: 80%;

    .title {
      margin-top: 0.1rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: $secondary-green;
    }

    .filters-container {
      .ant-form-item-label>label {
        font-size: 1.1rem !important;
        color: #828282;
      }
    }
  }

  .table-container {
    margin: auto;

    .bill-selection {
      margin-top: 3rem;

      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }

      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-table-thead>tr>th {
      padding: 0.5rem;
    }

    .ant-table-tbody>tr>td>.ant-btn {
      border-radius: 0.5rem;
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .ant-table {
      font-size: 1rem;

      .ant-table-column-sorters {
        padding: 2px;
      }
    }

    .format-number {
      white-space: nowrap;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-select {
    width: 100%;
    border-radius: 5px;
    height: 1.8rem;
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;

    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-tabs-tab {
    line-height: 4rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: $secondary-blue;
  }

  .ant-tabs-ink-bar {
    height: 0.5rem !important;
    background-color: $secondary-blue;
    bottom: 7px;
  }

  .ant-tabs-nav-container {
    margin-bottom: -1rem;
  }

  .ant-tabs-bar {
    outline: 0.55rem solid gray;
  }

  .ant-pagination {
    font-size: 1rem;

    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }

      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table {
      overflow: auto;
    }
  }

  .info {
    color: #00c1ed;
    transition: 0.2s;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      transition: 0.2s;
      transform: scale(1.1);
    }
  }

  .ptable {
    margin-top: 4rem;
    font-size: 1.3rem;
    text-align: left;
  }

  .sub-title {
    font-size: 1.3rem;
    text-align: left;
  }

  .row-agregar {
    float: right;
    position: relative;
    margin-right: 1rem;
  }

  .btn-agregar {
    width: 100%;
    min-height: 4rem;
    max-width: 12rem;
    padding: 0.5rem;
    color: #1890ff;
    font-weight: 500;
    border: #1890ff 3px solid;
    border-radius: 5px;
    white-space: normal;

    &:hover {
      opacity: 0.5;
    }
  }

  .task-list {
    .task {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      margin: 2rem 0;

      .task-label {
        font-size: 1.2rem;
      }

      .task-button-component {
        width: 200px;
        background-color: #09d96d;
        color: white;
        font-weight: 500;
        border: none;
        border-radius: 5px;
      }
    }
  }

}
