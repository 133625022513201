@import '../../styles/variables.scss';
.token-label {
  .time {
    margin-left: 1rem;
    p {
      margin: 0;
    }
  }
  .btn-next {
    background-color: #09d96d;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
  }

  .active {
    color: white;
    cursor: pointer;
  }
  .disabled {
    color: white;
  }
}

.token-input {
  margin-left: -8px;
  .ant-input-affix-wrapper-sm {
    border-radius: 0.5rem;
  }
}
