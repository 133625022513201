@import '../../../styles/standard.scss';

.chargeall-style {
  padding: 2% 6.5%;
  .ant-card-body {
    padding: 1px;
  }

  .shop-info-container {
    position: relative;
    display: inline-block;
    left: -40px;
    padding-left: 22px;
    .shop-card {
      border: $primary-green solid 1px;
      display: inline-block;
      width: 100%;
      height: 3.2rem;
      position: relative;
      top: 14px;
      line-height: 1.5rem;
      .icon {
        display: inline-block;
        font-size: 2.4rem;
        position: relative;
        top: 5px;
      }
      .money-text {
        display: inline-block;
        font-size: 1rem;
        position: relative;
        top: -3px;
        font-weight: 700;
      }
    }
  }

  .summary {
    width: 50%;
    display: inline-block;
    position: relative;
    left: -25px;
    padding-left: 25px;
    margin-top: 5px;
    margin-bottom: 9px;
    border-left: $primary-green solid 2px;
    line-height: 19px;
  }

  .investors-container {
    margin-top: 1.4%;
    .investor-card {
      text-align: center;
      user-select: none;
      .title {
        color: $font-color-light;
        margin-top: 0.7rem;
        height: 44px;
      }
      .logo-container {
        min-height: 100px;
        .logo {
          width: 60%;
          padding: 0;
          margin: auto;
          display: block;
        }
      }

      .value {
        font-size: 1.3rem;
        color: $font-color-title;
        font-weight: 400;
      }

      .payers {
        margin-top: 1.7rem;
        font-size: 1rem;
        font-weight: 600;
        color: $font-color-title;
      }

      .other-data {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
        color: $font-color-title;
      }

      .rate {
        margin-top: 0.3rem;
        color: #3399fe;
        font-weight: 500;
        font-size: 1rem;
      }

      .bill-amount {
        font-weight: 600;
        font-size: 1rem;
      }

      .conditions {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        color: #3399fe;
        padding-bottom: 5%;
      }
      &.active {
        &.selected {
          transform: scale(1.05);
          box-shadow: 0px 0px 5px 3px rgba(21, 236, 136, 0.94);
        }
        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 0px 5px 3px rgba(78, 218, 251, 0.94);
        }
      }

      &.disabled {
        user-select: none;
        &::after {
          content: attr(error-message);
          color: black;
          font-weight: 900;
          padding-top: 9rem;
          font-size: 1.5rem;
          background-color: #e2e2e2;
          opacity: 0.9;
          top: -1px;
          position: absolute;
          right: -1px;
          left: -1px;
          bottom: -1px;
          border-radius: 5px;
        }
      }
    }
  }

  .first-message {
    margin-top: 1.7rem;
    padding: 1.2rem 0 2.3rem 0;
    img {
      width: 100%;
    }
    .title {
      font-size: 1.2rem;
      color: #449cf3;
      font-weight: 700;
    }

    .description {
      font-size: 1rem;
      color: #7e7e7e;
      line-height: 1.8rem;
    }
  }
  .ant-table-wrapper {
    overflow: auto;
  }

  thead .ant-table-cell {
    text-align: center;
    background-color: white;
    font-size: 1rem;
    border-bottom: white 1px solid;
    font-weight: 600;
  }

  .ant-table-tbody {
    background-color: #f0f0f0;
    text-align: center;
    font-weight: 600;
    tr:nth-child(even) {
      background-color: white;
    }

    td.ant-table-cell {
      .ant-picker {
        border: none;
        background-color: transparent;
      }
    }

    .offer-value {
      color: $secondary-green;
      font-weight: 500;
    }

    .current-founder {
      color: #828282;
      font-weight: 400;
      .anticon.anticon-shopping-cart {
        font-size: 1.5rem;
      }
    }

    .has-credits {
      border: 1px #3399ff solid;
      border-radius: 5px;
      user-select: none;
      cursor: pointer;
      padding: 0.2rem 0.5rem;
      &:hover {
        transition: 0.2s;
        background-color: #3399ff;
        color: white;
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th {
    padding: 16px 0;
  }

  .calendar {
    svg {
      width: 27px;
      height: 27px;
    }
  }

  .info {
    svg {
      width: 27px;
      height: 27px;
    }
  }

  .select-label {
    color: $font-color-light;
    font-size: 1rem;
    padding-top: 0.6rem;
  }

  .select-container {
    padding-top: 0.5rem;
    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-radius: 0.5rem;
      }
    }
  }

  .footer {
    margin-top: 1rem;
    padding: 0 1.1rem;
    .text {
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 1rem;
      text-align: right;
      padding-right: 1rem;
    }

    .value {
      padding-right: 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 1rem;
      text-align: center;
      &-offer {
        color: $secondary-green;
      }

      &-credit {
        color: $primary-blue;
      }
    }
  }

  // .ant-table-pagination.ant-pagination {
  //   display: none;
  // }

  .switch-container {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-right: 0.9rem;
    .ant-switch {
      height: 1.6rem;
      background-color: $secondary-green;
      box-shadow: inset 0 0 5px #2c6195;
      border: #128649 1px solid;
      .ant-switch-inner {
        font-weight: 600;
        color: #007a1e;
        padding-bottom: 0.1rem;
        padding-left: 1.7rem;
        padding-right: 0.5rem;
      }
      &::after {
        width: 22px;
        height: 22px;
        top: 1px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
    }
    .ant-switch-checked {
      background-color: #3299ff;
      border: #27629a 1px solid;
      .ant-switch-inner {
        color: #004198;
      }
    }
  }

  .show-icon-container {
    span {
      font-size: 1.8rem;
      position: relative;
      float: right;
      transition: 0.2s;
      &.rotated {
        transition: 0.2s;
        transform: rotate(180deg);
      }
      &:hover {
        transition: 0.2s;
        transform: scale(1.2);
      }
    }
  }

  .btn-next-container {
    margin-top: 1rem;
    .btn-next {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &.disabled {
        background-color: #828282;
      }
    }
  }

  .table-collapse {
    position: relative;
    .ant-table-wrapper {
      height: auto;
    }
    &.collapsed {
      .ant-table-wrapper {
        height: 0;
      }
    }
  }

  span.caret-open {
    &:hover {
      transform: rotate(180deg);
    }
    transform: rotate(180deg);
  }
}

@media (max-width: 1160px) {
  .billing-style {
    .footer {
      .text {
        font-size: 1rem;
      }
      .value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1044px) {
  .billing-style {
    .footer {
      .text {
        font-size: 1rem;
      }
      .value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1024px) {
  .billing-style {
    .bill-style .bill-table-container .card .ant-card-body .client {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
    .footer {
      .text {
        font-size: 1rem;
      }
      .value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .billing-style .investors-container .investor-card .logo-container .logo {
    width: 35%;
  }
}
