
@import "../../../../styles/variables.scss";

.bills-events-style {
  padding: 2% 6.5%;

  .header-container {
    width: 90%;
    padding: 0 5%;

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
    }

    .form-btn {
      margin-top: 2rem;
      width: 100%;
      border: none;
      background-color: $primary-blue;
    }

    .ant-form-item-label>label {
      font-size: 1.1rem !important;
      color: #828282;
    }
  }

  .table-container {

    .bill-selection {
      margin-top: 3rem;
      display: flex;

      .title {
        display: block;
        font-weight: 600;
        font-size: 1.6rem;
        margin: 0;
        padding: 0;
      }

      .select-label {
        display: block;
        margin: 0;
        padding: 0;
      }

      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .ant-table {
      font-size: 1rem;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-select {
    width: 100%;
    border-radius: 5px;
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;

    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .detail-container {
    width: 65%;
    margin: 4rem auto;
  }

  .table-container {
    width: 95%;
    margin: auto;

    .ant-table {
      font-size: 1rem;
    }

    .bill-selection {
      margin-top: 3rem;

      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }

      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .btn-excel {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }

    .export {
      text-align: right;
    }
  }

  .ant-tabs-tab {
    padding: 1.5rem 2rem;
    line-height: 4rem;
    font-weight: bold;
    font-size: 1rem;
    color: $secondary-blue;
  }

  .ant-pagination {
    font-size: 1rem;

    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }

      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }

  .ant-table-wrapper {
    overflow: auto;
  }

  .btn-details {
    height: 2rem;
    font-size: 1rem;
  }

  .info {
    color: #00c1ed;
    transition: 0.2s;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      transition: 0.2s;
      transform: scale(1.1);
    }
  }

  .ant-table-tbody>tr>td>.ant-btn {
    border-radius: 0.5rem;
  }

  .btn-blue {
    background-color: $primary-blue !important;
  }

  .ptable {
    margin: 2rem 0 0 2rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left;
  }

  .sub-title {
    font-size: 1.3rem;
    text-align: left;
  }

  .row-agregar {
    float: right;
    position: relative;
    margin: 1.4rem 0rem
  }

  .btn-agregar {
    width: 100%;
    min-height: 3rem;
    max-width: 12rem;
    padding: 0.5rem;
    color: #fff;
    font-weight: 500;
    border: $secondary-green 1px solid;
    border-radius: 5px;
    white-space: normal;
    margin-top: 5%;
    background-color: $secondary-green;

    &:hover {
      opacity: 0.5;
      background-color: $secondary-green;
    }
  }

  .btn-listar {
    width: 100%;
    min-height: 3rem;
    max-width: 12rem;
    padding: 0.5rem;
    color: #fff;
    font-weight: 500;
    border: $primary-blue 1px solid;
    border-radius: 5px;
    white-space: normal;
    margin-top: 5%;
    background-color: $primary-blue;

    &:hover {
      opacity: 0.5;
      background-color: $primary-blue;
    }
  }

  .left-tittle {
    text-align: initial;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .ant-form-item-control-input-content {
    text-align: start;
  }

  .texto-exportar {
    margin: -0.5rem 2.5rem 0rem;
  }


  .button-down {
    align-self: flex-end;
  }
}

.ant-modal-footer {
  .ok-btn {
    background-color: $secondary-green;
    border-color: $secondary-green;
  }
}

.operation-modal-style {
  width: 80% !important;

  .ant-modal-content {
    padding: 2rem 4rem;
  }

}

.radio-btn {
  border-radius: 2rem !important;
  margin: 0.2rem 0.5rem;
  color: gray;
  border: 0.1rem solid;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.btn-modal-configurar {
  border-radius: 0.5rem;
  background-color: transparent;
  color: #40a9ff;
  width: 20%;
  align-self: center;
  margin-left: 1rem;
  border: 0.2rem solid #40a9ff;
}

.btn-primary {
  width: 100%;
  border: none;
  height: 1.8rem;
  line-height: 1rem;
  background-color: $primary-blue;
  top: 2.2rem;
}

.btn {
  margin-top: 1rem;
  width: 14rem;
  height: 2.5rem;
  border-radius: 0.6rem;
  color: white;

  &.btn-blue {
    background-color: $primary-blue;
  }

  &.btn-blue-events {
    background-color: $primary-blue;
    margin-left: 45rem;
  }

  &.btn-green {
    border-color: #38d380;
    background-color: $primary-green;
  }
}

.btn.btn-green:hover {
  color: #fff;
  background: #74eead;
  border-color: #74eead;
}

.p-modal {
  font-size: 1.3rem;
}

.p-modal-margin {
  margin-top: -1rem;
}

.column-center {
  align-self: center;
}

.button-down {
  align-self: flex-end;
}

.check {
  align-self: center;

  .check-container {
    padding-top: 0.6rem;
    padding-left: 1.9rem;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      width: 60%;
      height: 60%;
      top: 50%;
      left: 50%;
      background-color: #1dcf77;
    }
  }

  .ant-checkbox-inner {
    width: 26px;
    height: 26px;
    border: none;
    background-color: #d3d3d3;
    border-radius: 0.2rem;

    &::after {
      width: 9px;
      height: 20px;
      top: 46%;
      left: 22%;
      border: 3px solid #1dcf77;
      border-top: 0;
      border-left: 0;
    }
  }

  .ant-checkbox-checked::after {
    border: 1px solid #1dcf77;
  }

  span {
    font-size: 1.2rem;
  }

}

.export {
  text-align: right;
}

.btn-excel {
  color: #fff;
  border-color: #38d380;
  background-color: $primary-green;
}

.btn-excel:hover {
  color: #fff;
  background: #74eead;
  border-color: #74eead;
}

.icon-size {
  font-size: 1.5rem;
  cursor: pointer;

  &.color-alert {
    color: #ff6a00;
  }

  &.color-error {
    color: #ff1100;
  }

  &.color-success {
    color: #4ba138;
  }

  &.color-info {
    color: #00c1ed;
  }
}
