@import '../../../../styles/standard.scss';

.finish-style {
  .track-percent-desc {
    p {
      text-align: center;
    }
  }
  .ant-spin-nested-loading > div > .ant-spin {
    height: 90vh;
    max-height: none;
  }
  .container {
    width: 100%;
    position: relative;
    margin: auto;
    padding: 0 3rem;
    .finish-message {
      img {
        width: 10.3rem;
        height: 10.3rem;
      }
      .title {
        margin-top: 3.2rem;
        color: $primary-green;
        font-weight: 400;
        margin-left: 1.3rem;
        font-size: 2.5rem;
      }

      .description {
        margin-left: 1rem;
        color: #c4c4c4;
      }
    }

    .state-container {
      margin-top: 5rem;
      .states {
        margin-top: 1rem;
        .text {
          margin-left: 4rem;
        }
      }
    }

    .track-progress-bar {
      height: 4rem;

      .ant-progress-inner {
        height: 50px;
        .ant-progress-bg {
          height: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgb(103, 253, 156);
          background: linear-gradient(90deg, rgba(103, 253, 156, 1) 42%, rgba(43, 202, 64, 0.9248074229691877) 69%);

          
          &::after {
            background-image: url(../../../../assets/img/truck-blanco.svg);
            background-size: 50px 45px;
            background-repeat: no-repeat;
            background-position: center;
            width: 50px;
            height: 45px;
            content: '';
          }
        }
        .progress-inner-empty {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          p {
            margin: 0;
            font-size: 1.2rem;
          }
        }
      }
    }
    .track-progress-bar.first {
      .ant-progress-inner {
        .ant-progress-bg {
          &::after {
          }
        }
      }
    }
    .track-progress-bar.second {
      .ant-progress-inner {
        .ant-progress-bg {
          justify-content: flex-end;
          &::after {
            flex: 0 0 50%;
          }
        }
      }
    }
    .track-progress-bar.third {
      .ant-progress-inner {
        .ant-progress-bg {
          justify-content: flex-end;
          &::after {
            &::after {
              flex: 0 0 33.33%;
            }
          }
        }
      }
    }
    .track-progress-bar.fourth {
      .ant-progress-inner {
        .ant-progress-bg {
          justify-content: flex-end;
          &::after {
            flex: 0 0 25%;
          }
        }
      }
    }

    .voucher-container {
      margin-top: 2rem;
      .title {
        color: $font-color-light;
        font-size: 1.5rem;
        font-weight: 400;
      }

      .mail-input {
        margin-top: 1rem;
        input {
          border-radius: 0.3rem;
          border-color: $border-color;
          box-shadow: $standar-shadow;
        }
        .send-button {
          background-color: $secondary-blue;
          border: none;
          font-weight: 500;
          letter-spacing: 0.03rem;
          color: white;
          border-radius: $button-radius;
          width: 100%;
        }
      }
    }

    .actions {
      margin-top: 3.2rem;
      .download-button {
        background-color: $secondary-blue;
        border: none;
        color: white;
        border-radius: $button-radius;
        width: 100%;
        font-weight: 500;
        letter-spacing: 0.03rem;
      }

      .track-button {
        border-radius: $button-radius;
        width: 100%;
        background-color: #0ad86c;
        border: none;
        font-weight: 500;
        letter-spacing: 0.03rem;
        color: white;
      }
    }
  }
}
