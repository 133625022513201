@import '../../../styles/standard.scss';

.login-style {
  .images {
    width: 50%;
    display: inline-block;
    .logo-container {
      padding: 10% 13%;
      img {
        width: 16.7rem;
      }
    }

    .people-container {
      margin-bottom: -1px;
      img {
        width: 26rem;
      }
    }
  }

  .login-form-container {
    width: 50%;
    position: absolute;
    margin-top: 5rem;
    display: inline-block;
    padding: 0 3rem;
    input {
      border-radius: 0.5rem;
      border: #cccccc 2px solid;
    }
    .ant-input-password.ant-input-affix-wrapper {
      input {
        border: none;
      }
      border-radius: 0.5rem;
      border: #cccccc 2px solid;
    }

    .recovery-container {
      margin-top: 0px;
      .link {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .login-button-container {
      margin-top: 1rem;
      .btn-login {
        width: 100%;
        color: white;
        border: none;
        background-color: #3399fd;
      }
    }

    .remember-button-container {
      margin-top: 0.5rem;
      .btn-login {
        width: 100%;
        color: white;
        border: none;
        background-color: #3399fd;
      }
    }

    .register-button-container {
      .btn-register {
        float: right;
        color: #008626;
        background-color: #17ff7f;
        font-weight: 500;
        border: none;
      }
    }

    .old-platform {
      margin-top: -1rem;
      text-align: center;
      color: $font-color-light;
      font-size: 1rem;
    }
    .privacy-policy {
      margin-top: 1rem;
      text-align: center;
      color: $font-color-light;
      font-size: 1rem;
    }

    .remember-init-text {
      font-size: 1rem;
    }

    .recaptchaLogin iframe {
      display: inline;
      transform: scale(0.87);
      transform-origin: 0 0;
      margin-top: 0.5rem;
    }

    #remember {
      margin-top: -2rem;
    }

    #remember_email {
      margin-bottom: 0.5rem;
    }
  }
}

.login-style-spin {
  height: 359.2px;
  .row-card {
    height: 100%;
  }
}
