@import '../../../styles/standard.scss';

.faq {
  font-family: Roboto;

  .faq-title {
    margin: 1.5rem 0;
    color: $font-color-light;
  }

  .faq-top-margin {
    margin-top: 2rem;
  }
}
