@import "../../../../styles/variables.scss";

.operation-style {
  padding: 2% 6.5%;

  .header-container {
    margin: auto;
    width: 65%;

    .align-left {
      text-align: left;
    }

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .second-title {
      font-size: 1.2rem;
      font-weight: 800;
    }

    .form-btn {
      margin-top: 2.3rem;
      width: 100%;
      border: none;
      background-color: $secondary-green;
    }

    .ant-form-item-label>label {
      font-size: 1.1rem !important;
      color: #828282;
    }
  }

  .table-container {
    .title {
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .current-quota {
      text-align: center;
      margin: auto;
      width: 90%;
    }

    margin: auto;

    .bill-selection {
      margin-top: 3rem;

      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }

      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-table-thead>tr>th {
      padding: 0.5rem;
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .btn-excel {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }

    .btn-info {
      background-color: #1890ff;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }

    .info {
      font-size: 1.5rem;
      color: #1890ff;
    }

    .btn-quota {
      width: 100%;
      min-height: 5rem;
      padding: 0.5rem;
      color: #1890ff;
      font-weight: 500;
      border: #1890ff 2px solid;
      border-radius: 5px;
      white-space: normal;

      &:hover {
        opacity: 0.5;
      }
    }

    .export {
      text-align: right;
    }

    .ant-table {
      font-size: 1rem;

      .ant-table-column-sorters {
        padding: 2px;
      }
    }

    .format-number {
      white-space: nowrap;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-select,
  .ant-input-number,
  .ant-select-selector {
    width: 100% !important;
    border-radius: 5px !important;
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;

    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-tabs-tab {
    line-height: 4rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: $secondary-blue;
  }





  .ant-tabs-nav::before {
    border-bottom: 0.55rem solid gray;
  }

  .ant-pagination {
    font-size: 1rem;

    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }

    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }

      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }

  .ant-table-wrapper {
    overflow: auto;
  }
}

.ant-modal-footer {
  .ok-btn {
    background-color: $secondary-green;
    border-color: $secondary-green;
  }
}

.modal-style {
  width: 80rem !important;

  .header-container {
    margin: auto;
    width: 90%;

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: $secondary-green;
    }

    .filters-container {
      .ant-form-item-label>label {
        font-size: 1rem !important;
      }

      .multiple-buttons {
        .ant-btn-round.ant-btn-lg {
          height: 20px;
          font-size: 1rem;
          line-height: 0px;
        }
      }
    }
  }

  .table-container {
    overflow: auto;

    .title {
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    margin: auto;

    .bill-selection {
      margin-top: 3rem;

      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }

      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-table-thead>tr>th {
      padding: 0.5rem;
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .btn-excel {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }

    .export {
      text-align: right;
    }

    .ant-table {
      font-size: 1rem;

      .ant-table-column-sorters {
        padding: 2px;
      }
    }

    .format-number {
      white-space: nowrap;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-input-number,
  .ant-select {
    width: 100%;
    border-radius: 5px;
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;

    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.5;
      }
    }
  }


}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  text-align: left;
}

.ant-picker-panel {
  .ant-picker-footer {
    display: inherit;
  }
}
