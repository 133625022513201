html {
  font-size: 14px;
}

.realasside {
  flex: 0 0 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
  width: 256px !important;
  background-color: white;
  min-width: 240px;
  width: 19%;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  &::-webkit-scrollbar-track:active {
    background: #333333;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  .user-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    text-align: center;
    line-height: 1.1rem;
    width: 100%;
    min-width: 200px;

    .brand-logo {
      width: 80%;
      height: 75px;
      padding: 1rem 1rem;
      margin: 0.8rem auto;
      display: block;
    }

    .user-avatar {
      border-radius: 3rem;
      width: 4.3rem;
      height: 4.3rem;
      margin: 2.1rem auto 0 auto;
    }

    p.user-name {
      margin: 0px;
      margin-top: 1rem;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 1px;
    }

    p.user-company {
      margin-top: 0.4rem;
      color: #bebebe;
      margin-bottom: 0;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      a,
      a:hover,
      a:visited,
      a:active {
        display: inline;
        color: #87b0dd;
      }

      a.user-change-company {
        font-size: 1rem;
        margin: 0 10px 10px 10px;
      }
    }
  }

  .slider-list {
    width: 100%;
    min-width: 200px;
    background-color: white;
    border: none;

    .ant-menu-item-selected {
      background-color: #166e8e !important;
      border-right-color: #166e8e !important;
      color: white;
    }

    .menu-item-margin-left {
      margin-left: 2.5rem;
    }
  }

  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 0.5rem;
      font-size: 0.7rem;
      height: 26px;
    }
  }

  .ant-select-arrow {
    top: 29%;
  }
}

.ant-badge-count {
  font-size: 1rem;
  line-height: 13px;
  min-width: 12px;
  height: 12px;
  width: 12px;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  margin-left: -2px;
}

.content {
  padding-left: 256px;

  .ant-layout-content {
    min-height: 100vh;
    overflow: hidden;
  }
  .header {
    position: relative;
    height: auto;
    color: white;
    background-color: #012b41;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    padding: 0px 33px 0px 33px;
    border-bottom: #022336 solid 8px;
    .menu {
      height: 3rem;
      color: white;

      .notification-icon {
        font-size: 1.4rem;
        color: white;
        &:hover {
          transition: 0.2s;
          transform: scale(1.2);
        }
      }

      .logout {
        cursor: pointer;
        transition: 0.2s;
      }
    }

    .custom-card-container {
      padding: 0.8rem;
    }

    .custom-card-body {
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      padding-bottom: 0.5rem;
      .card-icon {
        line-height: 2rem;
        font-size: 1.5rem;
        margin: 1rem;
      }
      .card-text {
        line-height: 1.5rem;
        width: 75%;
        font-size: 1rem;
        color: #f9f9f9;
        margin-top: 0.8rem;
        span {
          font-weight: bold;
          font-size: 1rem;
          color: white;
        }
      }
    }

    .card-color1 {
      background-color: #36d381;
    }
    .card-color2 {
      background-color: #309bfd;
    }
    .card-color3 {
      background-color: #a394ff;
    }
  }
}

.ant-drawer-body {
  .ant-badge-dot {
    background-color: #3399ff;
  }
  padding: 0;
  .notification-card {
    .ant-badge-dot {
      margin-top: 5px;
      margin-right: 14px;
    }
    .title {
      color: #b0bac9;
      letter-spacing: 2px;
      font-size: 1rem;
      text-align: left;
    }
    .description {
      color: black;
      font-size: 1rem;
      font-weight: 600;
      &-readed {
        font-weight: 100;
      }
    }
    .icon-container {
      background-color: #f2f3f6;
      border: none;
      .icon {
        color: #3399ff;
      }
    }
    .date-row {
      margin-top: 0.5rem;
      .date {
        color: #b0bac9;
      }
    }
  }
}

/*Correccion de Antd Switch*/
.ant-switch-inner {
  margin: 0 6px 0 24px !important;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 24px 0 6px !important;
}

table tr th.ant-table-selection-column, table tr td.ant-table-selection-column {
min-width: unset;
}

.ant-table-thead > tr > th{
  min-width: 10rem;
}

.modal-condiciones{
  width: 70% !important;
  height: 80vh !important;
}

.modal-condiciones > .ant-modal-content{
  width: 100% !important;
  height: 100% !important;
  .ant-modal-body{
    height: 100%;
    .ant-modal-confirm-body-wrapper{
      height: 95%;
      .ant-modal-confirm-body{
        height: 95%;
        .ant-modal-confirm-content{
          height: 95%;
        }
      }
    }
  }
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: white
}