@import "../../styles/variables.scss";

.modal-style-volumen {
    .ant-modal-header {
        .ant-modal-title {
            font-weight: bold;
        }
    }

    .sub-title {
        font-size: 1.2rem;
        text-align: left;
    }
}
