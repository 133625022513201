@import "../../../styles/variables.scss";

.task-style {
  

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    .header-container {
      width: 80%;
      text-align: left;
      .title {
        margin-bottom: 5rem;
        font-size: 1.3rem;
        font-weight: 600;
      }
    }
    

    .detail-container {
      width: 80%;

      .form-label {
        margin: 0 0.5rem;
      }

      .ant-row{
        margin-bottom: 4rem;
      }
      .module-selector-select {
        width: 100%;
      }

      .task-list {
        margin-top: 5rem;
        .task {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          margin: 2rem 0;

          .task-label {
            font-size: 1.2rem;
          }

          .task-button-component {
            width: 200px;
            background-color: #09d96d;
            color: white;
            font-weight: 500;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
