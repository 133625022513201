@import '../../../../styles/standard.scss';

.bill-style {
  .welcome-message {
    margin-top: 2rem;
    text-align: center;

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .subtitle {
      color: #858282;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }

  .ant-spin-nested-loading>div>.ant-spin {
    height: 90vh;
    max-height: none;
  }

  .title-info {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
    text-align: left;
  }

  .bill-selection {
    margin-top: 3rem;

    .title {
      display: block;
      font-weight: 600;
      font-size: 1.6rem;
      margin: 0;
      padding: 0;
    }

    .select-label {
      display: block;
      margin: 0;
      padding: 0;
    }

    .select-container {
      display: block;
      margin: 0;
      padding: 0;
    }

    .button {
      display: block;
      margin: 0;
      padding: 0;

      button {
        margin: 0;
        padding: 0;
      }
    }
  }

  .bill-table-container {
    .card {
      margin-top: 1.5rem;

      .ant-card-body {
        padding: 1rem 1.5rem;

        .client {
          color: $secondary-green;
          font-size: 1.4rem;
          font-weight: 600;
        }

        .select-label {
          font-size: 0.9rem;
          padding-top: 0;
          padding-left: 1.6rem;
        }

        .select-all-label {
          color: $font-color-light;
          font-size: 0.8rem;
          padding-top: 0.6rem;
          text-align: right;
        }

        .check-container {
          padding-top: 0.6rem;
          padding-left: 1.9rem;
        }

        .ant-checkbox-indeterminate {
          .ant-checkbox-inner::after {
            width: 60%;
            height: 60%;
            top: 50%;
            left: 50%;
            background-color: #1dcf77;
          }
        }

        .ant-checkbox-inner {
          width: 26px;
          height: 26px;
          border: none;
          background-color: #d3d3d3;
          border-radius: 0.2rem;

          &::after {
            width: 9px;
            height: 20px;
            top: 46%;
            left: 22%;
            border: 3px solid #1dcf77;
            border-top: 0;
            border-left: 0;
          }
        }

        .ant-checkbox-checked::after {
          border: 1px solid #1dcf77;
        }

        .ant-divider {
          background-color: #dfdfdf;
        }

        .ant-divider-horizontal {
          margin: 24px 0 0 0;
        }

        .table-container {
          margin-top: 1rem;
        }
      }
    }

    .paginator {
      margin-top: 1.4rem;

      ul {
        position: relative;
        margin: auto;
      }
    }
  }

  .ant-pagination {
    text-align: center;
    float: unset;

    .ant-pagination-item {
      border: none;
      min-width: 25px;
      height: 25px;
      font-size: 0.7rem;
      line-height: 24px;
    }

    .ant-pagination-item-active {
      background-color: #09d56b;
      border-radius: 50%;

      a {
        color: white;
      }
    }

    .ant-pagination-item-link {
      border: none;
      padding-top: 0.2rem;

      span {
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: transparent;
    }
  }

  .date-container {
    margin-top: 0.5rem;

    .ant-picker {
      width: 100%;
      border-radius: 0.5rem;
    }
  }

  .btn-excel {
    background-color: #09d96d;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 0.5rem;

    &:hover {
      opacity: 0.5;
    }
  }

   .btn-upload {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
  }

  .no-check {
    .ant-table-selection-column {
      display: none;
    }
  }

  .empty-bills {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-weight: 600;
  }

  .btn-cufe {
    font-weight: 500;
    border-color: #09d96d;
    color: #09d96d;
    background: white;
    border-radius: 5px;
  }
}
