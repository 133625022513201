@import "../../../../styles/variables.scss";

.parameterization-style {
  padding: 2% 6.5%;

  .header-container {
    margin: auto;
    width: 65%;

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: $secondary-green;
      top: 2.2rem;
    }

    .filters-container {
      .ant-form-item-label > label {
        font-size: 1.1rem !important;
        color: #828282;
      }
    }
  }

  .table-container {
    margin: auto;
    .bill-selection {
      margin-top: 3rem;
      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }
      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-table-cell{
      p{
        margin: 0;
      }
    }

    .ant-table-thead > tr > th {
      padding: 0.5rem;
    }

    .ant-table-tbody > tr > td > .ant-btn {
      border-radius: 0.5rem;
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .btn-excel {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }

    .export {
      text-align: right;
    }

    .ant-table {
      font-size: 1rem;
      .ant-table-column-sorters {
        padding: 2px;
      }
      p.value-wrap {
        max-width: 10rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }

    .format-number {
      white-space: nowrap;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-select {
    width: 100%;
    border-radius: 5px;
    height: 1.8rem;
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;
    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-tabs-tab {
    line-height: 4rem;
    font-weight: bold;
    font-size: 1rem;
    color: $secondary-blue;
  }

  

  

  

  .ant-pagination {
    font-size: 1rem;
    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }
    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }
      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table{
      overflow: auto;
    }
  }

  .info {
    color: #00c1ed;
    transition: 0.2s;
    svg {
      width: 24px;
      height: 24px;
    }
    &:hover {
      transition: 0.2s;
      transform: scale(1.1);
    }
  }

  .ptable {
    margin-top: 2rem;
    font-size: 1.3rem;
    text-align: left;
  }

  .label-lista {
    margin-top: 0.7rem;
    font-size: 1.3rem;
    text-align: left;
    margin-bottom: 4rem;
  }

  .cuadro-vencidos {
    margin: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: #ffabab;
  }

  .row-left {
    margin-left: 2.5rem;
  }
}

.modal-style {
  width: 45rem !important;
}

.ant-form-item-label > label.ant-form-item-required::before{
  color: rgba(0,0,0,0.85)
}
