@import "../../../../styles/variables.scss";

.reports {
  .report-header {
    margin-top: 2rem;
    margin-bottom: 3rem;
    text-align: center;
    font-size: 1.6rem;
    .blue {
      color: #3399ff;
      font-weight: 600;
    }
    .gray {
      color: #828282;
      font-weight: 500;
    }
  }
  .reports-container {
    width: 100%;
    position: relative;
    padding: 2% 2%;
    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: #09d96d;
    }
    &.card-shadow {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.35);
    }
    &-large {
      height: 15rem;
    }
    .ant-input,
    .ant-picker,
    .ant-select,
    .ant-select-selector {
      width: 100%;
      border-radius: 5px;
    }
    .first-title{
      text-align: center;
      font-size: 1.2rem;
      font-weight: 800;
    }
  }
  .filter-card,
  .filter-card-large {
    height: 25rem;
    .ant-card-body {
      padding: 0.8rem 2rem;
    }
    .card-title {
      color: #919191;
      font-weight: 500;
      font-size: 1.4rem;
    }
    .report-title {
      color: #919191;
    }
    .input-container {
      label {
        color: #919191;
      }
    }
    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
    }
    &.card-shadow {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.35);
    }
    &-large {
      height: 15rem;
    }
  }

  padding: 0.8% 6%;
  .header-container {
    margin: auto;
    width: 65%;

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }

    .form-btn {
      width: 100%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: $secondary-green;
    }

    .filters-container {
      .ant-form-item-label > label {
        font-size: 1.1rem !important;
        color: #828282;
      }
    }
  }

  .table-container {
    margin: auto;
    .bill-selection-core {
      margin-top: 1rem;
      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }
      .select-container {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .ant-table-thead > tr > th {
      padding: 0.5rem;
    }

    .ant-select-selector {
      border-radius: 0.5rem;
    }

    .btn-excel {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }

    .btn-text {
      background-color: $primary-blue;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }

    .btn-pdf {
      background-color: $red-pdf;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }

    .btn-default {
      background-color: $font-color-light;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }

    .export {
      text-align: right;
    }

    .ant-table {
      font-size: 1rem;
      .ant-table-column-sorters {
        padding: 2px;
      }
    }

    .format-number {
      white-space: nowrap;
    }
  }

  .notify-container {
    text-align: right;
    margin-top: 2rem;
    .btn-notify {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-tabs-tab {
    line-height: 4rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: $secondary-blue;
  }

  

  

  

  .ant-pagination {
    font-size: 1rem;
    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }
    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }
      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table{
      overflow: auto;
    }
  }

  .row-agregar {
    float: right;
    position: relative;
    margin-right: 1rem;
  }

  .texto-exportar{
    margin: 0 1.5rem;
  }
}

.btn {
  margin-top: 1rem;
  width: 12rem;
  height: 2.2rem;
  border-radius: 0.6rem;
  color: white;
  &.btn-blue {
    background-color: $primary-blue;
    margin-left: 1rem;
  }
  &.btn-green {
    border-color: #38d380;
    background-color: $primary-green;
  }
}

.modal-style-core {
  width: 80rem !important;
}
