@import '../../../../styles/standard.scss';

.summary-style {
  .ant-spin-nested-loading > div > .ant-spin {
    height: 90vh;
    max-height: none;
  }
  .first-message {
    margin-top: 1.7rem;
    padding: 1.2rem 0 2.3rem 0;
    img {
      width: 100%;
    }
    .title {
      font-size: 1.6rem;
      color: #449cf3;
      font-weight: 700;
    }

    .description {
      font-size: 1.5rem;
      margin-top: 3rem;
      color: #7e7e7e;
      line-height: 1.8rem;
    }
  }

  .total {
    margin-top: 2.3rem;
    .text {
      font-size: 2.3rem;
      color: #2ac37b;
    }
    .value {
      font-size: 2.5rem;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .table-container {
    padding: 0 10rem;
    .ant-divider-horizontal {
      height: 4px;
    }

    .ant-table-pagination.ant-pagination {
      display: none;
    }
  }

  .account-container {
    margin-top: 1rem;
  }

  .action-btn-container {
    display: flex;
    margin-top: 1rem;
    justify-content: space-evenly;
    .btn-prev {
      background: #00c1ed;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      width: 10rem;
    }
  }

  .btn-next {
    background-color: #09d96d;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
  }
}

.summary-modal {
  .margin-top {
    margin-top: 1rem;
  }
  .select-label {
    margin-top: 0.5rem;
  }
  .switch-container {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-right: 0.9rem;
    .ant-switch {
      height: 1.6rem;
      background-color: $secondary-green;
      box-shadow: inset 0 0 5px #2c6195;
      border: #128649 1px solid;
      .ant-switch-inner {
        font-weight: 600;
        color: #007a1e;
        padding-bottom: 0.1rem;
        padding-left: 1.7rem;
        padding-right: 0.5rem;
      }
      &::after {
        width: 22px;
        height: 22px;
        top: 1px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
    }
    .ant-switch-checked {
      background-color: #3299ff;
      border: #27629a 1px solid;
      .ant-switch-inner {
        color: #004198;
      }
    }
  }
  .ant-modal-header {
    background-color: lightgray;
  }
  .ant-table-wrapper {
    overflow: auto;
  }

  .ant-table-row {
    text-align: center;
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th {
    padding: 16px 0;
  }

  .ant-table-tbody {
    background-color: #f0f0f0;
    text-align: center;
    font-weight: 600;
    tr:nth-child(even) {
      background-color: white;
    }

    .offer-value {
      color: $secondary-green;
      font-weight: 500;
    }
  }

  .ant-table-cell {
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .summary-style {
    .table-container {
      padding: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .summary-style {
    .first-message {
      .title {
        font-size: 1.2rem;
        color: #449cf3;
        font-weight: 700;
      }
    }
  }
}
