.module-selector-wrapper {
  .module-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: rgba($color: #000000, $alpha: 0.65);
    margin: auto;
    .module-selector-select {
      width: 12rem;
      text-align: center;
    }
    span {
      line-height: 2.5rem;
    }
  }
}
