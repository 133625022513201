@import '../../../styles/standard.scss';
@import '../../../styles/variables.scss';

.changepass-style {
  .welcome-message {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    .title {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }
    .subtitle {
      color: #858282;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }

  .ant-form {
    .ant-form-item-control-input-content {
      .ant-select-selector,
      .ant-picker,
      .ant-input {
        border-color: $border-color;
      }
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 0.3rem;
  }
}
