@import '../../../styles/standard.scss';
@import '../../../styles/variables.scss';

.agreements {
  font-family: Roboto;
  .welcome-message {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    .title {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }
    .subtitle {
      color: #858282;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }

  .agree-marginbot {
    margin-bottom: 2rem;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .agree-buttons {
    width: 10rem;
    height: 3.5rem;
    font-weight: bold;
    border-radius: 0.5rem;
    box-shadow: $standar-shadow;

    &.agree-active.active {
      border: 2px solid;
      color: $primary-blue;
      border-color: $primary-blue;
    }

    &.agree-active {
      color: $primary-blue;
    }

    &.agree-new {
      border: 2px solid;
      color: $primary-green;
      border-color: $primary-green;
    }
  }

  //tabla
  .agree-icon-size {
    //text-align: center;
    font-size: 1.5rem;
    cursor: pointer;

    &.agree-color-prim {
      color: $primary-blue;
    }
  }

  //modificar convenio
  .activeSwitch {
    width: 6rem;
    padding-left: 1rem;
  }

  .agree-mod-filters-marginbot {
    margin-bottom: 0.5rem;
  }

  .agree-dropd-width-max {
    width: 100%;
  }

  .agree-mod-convenio-center {
    // text-align: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    .ant-input-number,
    .ant-input-numbero:hover {
      border: none;
      width: 55px;
    }

    .ant-input-number-handler-wrap {
      opacity: 1;
      .ant-input-number-handler-up,
      .ant-input-number-handler-down {
        border: 1px solid $font-color-light;
      }
      .ant-input-number-handler-up {
        border-top-left-radius: 2px;
      }

      .ant-input-number-handler-down {
        border-bottom-left-radius: 2px;
      }
    }
  }

  .agree-mod-guardar {
    background-color: $primary-green;
    color: white;
    margin: 1.5rem;
  }

  .aggrement-row-active {
    color: $primary-blue;
  }
  .aggrement-row {
    padding: 8px;
  }
  .aggrement-row-inactive {
    color: $font-color-light;
  }

  .select-wide {
    width: 100%;
  }
  .agree-mod-name {
    color: $primary-green;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;

    .agree-form-name-reset {
      color: black;
      font-size: 1rem;
      font-weight: 400;
      text-transform: none;
    }
  }
  .card-container {
    width: 100%;
    .ant-row {
      margin: 1.5rem;
    }
    .btn-next {
      background-color: #09d96d;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
    }
  }
}
