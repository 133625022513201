@import '../../../../../styles/standard.scss';

//@import '../../../../../styles/standard.scss';

.billing-style {
  padding: 2% 6.5%;

  .ant-card-body {
    padding: 1px;
  }

  .shop-info-container {
    position: relative;
    display: inline-block;
    left: -40px;
    padding-left: 22px;

    .shop-card {
      border: $primary-green solid 1px;
      display: inline-block;
      width: 100%;
      height: 3.2rem;
      position: relative;
      top: 14px;
      line-height: 1.5rem;

      .icon {
        display: inline-block;
        font-size: 2.4rem;
        position: relative;
        top: 5px;
      }

      .money-text {
        display: inline-block;
        font-size: 1rem;
        position: relative;
        top: -3px;
        font-weight: 700;
      }
    }
  }

  .summary {
    width: 50%;
    display: inline-block;
    position: relative;
    left: -25px;
    padding-left: 25px;
    margin-top: 5px;
    margin-bottom: 9px;
    border-left: $primary-green solid 2px;
    line-height: 19px;
  }

  .investors-container {
    margin-top: 1.4%;

    .investor-card {
      height: 100%;
      text-align: center;
      user-select: none;

      .title {
        color: $font-color-light;
        margin-top: 0.7rem;
        height: 44px;
      }

      .logo-container {
        min-height: 100px;
        padding-top: 1rem;

        .logo {
          width: 45%;
          padding: 0;
          margin: auto;
          display: block;
          max-width: 8rem;
        }
      }

      .logo-B {
        padding-top: 0.3rem;
      }

      .logo-C {
        padding-top: 0;
      }

      .value {
        font-size: 1.3rem;
        color: $font-color-title;
        font-weight: 600;
      }

      .payers {
        margin-top: 1.7rem;
        font-size: 1rem;
        font-weight: 600;
        color: $font-color-title;

        p {
          margin-bottom: 0;
        }
      }

      .other-data {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
        color: $font-color-title;
      }

      .rate {
        margin-top: 0.3rem;
        font-weight: 600;
        font-size: 1rem;
      }

      .conditions {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        color: #3399fe;
        padding-bottom: 5%;
      }

      &.active {
        &.selected {
          transform: scale(1.05);
          box-shadow: 0px 0px 5px 3px rgba(21, 236, 136, 0.94);
        }

        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 0px 5px 3px rgba(78, 218, 251, 0.94);
        }
      }

      &.disabled {
        user-select: none;

        &::after {
          content: attr(error-message);
          color: black;
          font-weight: 500;
          padding-top: 9rem;
          font-size: 1.3rem;
          background-color: #e2e2e2;
          opacity: 0.9;
          top: -1px;
          position: absolute;
          right: -1px;
          left: -1px;
          bottom: -1px;
          border-radius: 5px;
        }
      }
    }
  }

  .first-message {
    margin-top: 1.7rem;
    padding: 1.2rem 0 2.3rem 0;

    img {
      width: 100%;
    }

    .title {
      font-size: 1.6rem;
      color: #449cf3;
      font-weight: 700;
      white-space: nowrap;
    }

    .description {
      font-size: 1.5rem;
      color: #7e7e7e;
      line-height: 1.8rem;
    }
  }

  .ant-table-wrapper {
    overflow: auto;
  }

  thead .ant-table-cell {
    text-align: center;
    background-color: white;
    font-size: 1rem;
    border-bottom: white 1px solid;
    font-weight: 600;
  }

  .ant-table-tbody {
    background-color: #f0f0f0;
    text-align: center;
    font-weight: 600;

    tr:nth-child(even) {
      background-color: white;
    }

    td.ant-table-cell {
      .ant-picker {
        border: none;
        background-color: transparent;
      }
    }

    .offer-value {
      color: $secondary-green;
      font-weight: 500;
    }

    .current-founder {
      color: #828282;
      font-weight: 400;

      .anticon.anticon-shopping-cart {
        font-size: 1.5rem;
      }
    }

    .has-credits {
      border: 1px #3399ff solid;
      border-radius: 5px;
      user-select: none;
      cursor: pointer;
      padding: 0.2rem 0.5rem;

      &:hover {
        transition: 0.2s;
        background-color: #3399ff;
        color: white;
      }
    }
  }

  .ant-table-tbody>tr>td,
  .ant-table-tbody>tr>th {
    padding: 16px 0;
  }

  .calendar {
    svg {
      width: 27px;
      height: 27px;
    }
  }

  .info {
    svg {
      width: 27px;
      height: 27px;
    }
  }

  .select-label {
    margin-top: 0.5rem;
  }

  .select-container {
    padding-top: 0.5rem;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border-radius: 0.5rem;
      }
    }
  }

  .footer {
    margin-top: 1rem;
    padding: 0 1.1rem;

    .text {
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 1rem;
      text-align: right;
      padding-right: 1rem;
    }

    .value {
      padding-right: 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 1rem;
      text-align: center;

      &-offer {
        color: $secondary-green;
      }

      &-credit {
        color: $primary-blue;
      }
    }
  }

  // .ant-table-pagination.ant-pagination {
  //   display: none;
  // }

  .switch-container {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-right: 0.9rem;

    .ant-switch {
      height: 1.6rem;
      background-color: $secondary-green;
      box-shadow: inset 0 0 5px #2c6195;
      border: #128649 1px solid;

      .ant-switch-inner {
        font-weight: 600;
        color: #007a1e;
        padding-bottom: 0.1rem;
        padding-left: 1.7rem;
        padding-right: 0.5rem;
      }

      &::after {
        width: 22px;
        height: 22px;
        top: 1px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
    }

    .ant-switch-checked {
      background-color: #3299ff;
      border: #27629a 1px solid;

      .ant-switch-inner {
        color: #004198;
      }
    }
  }

  .show-icon-container {
    span {
      font-size: 1.8rem;
      position: relative;
      float: right;
      transition: 0.2s;

      &.rotated {
        transition: 0.2s;
        transform: rotate(180deg);
      }

      &:hover {
        transition: 0.2s;
        transform: scale(1.2);
      }
    }
  }

  .btn-next {
    background-color: #09d96d;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;

    &.disabled {
      background-color: #828282;
    }
  }

  .table-collapse {
    position: relative;

    .ant-table-wrapper {
      height: auto;
    }

    &.collapsed {
      .ant-table-wrapper {
        height: 0;
      }
    }
  }

  span.caret-open {
    &:hover {
      transform: rotate(180deg);
    }

    transform: rotate(180deg);
  }
}

.ant-picker-footer {
  display: none;
}

@media (max-width: 1160px) {
  .billing-style {
    .footer {
      .text {
        font-size: 1rem;
      }

      .value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1044px) {
  .billing-style {
    .footer {
      .text {
        font-size: 1rem;
      }

      .value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1024px) {
  .billing-style {
    .bill-style .bill-table-container .card .ant-card-body .client {
      margin-top: 0.5rem;
      font-size: 1rem;
    }

    .footer {
      .text {
        font-size: 1rem;
      }

      .value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .billing-style .investors-container .investor-card .logo-container .logo {
    width: 35%;
  }
}

.bills-style {
  margin: 3rem;

  .welcome-message {
    margin-top: 2rem;
    text-align: center;

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .subtitle {
      color: #858282;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }

  .ant-spin-nested-loading>div>.ant-spin {
    height: 90vh;
    max-height: none;
  }

  .title-info {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
    text-align: left;
  }

  .bill-selection {
    margin-top: 3rem;

    .title {
      display: block;
      font-weight: 600;
      font-size: 1.6rem;
      margin: 0;
      padding: 0;
    }

    .select-label {
      display: block;
      margin: 0;
      padding: 0;
    }

    .select-container {
      display: block;
      margin: 0;
      padding: 0;
    }

    .button {
      display: block;
      margin: 0;
      padding: 0;

      button {
        margin: 0;
        padding: 0;
      }
    }
  }

  .bill-table-container {
    .card {
      margin-top: 1.5rem;

      .ant-card-body {
        padding: 1rem 1.5rem;

        .client {
          color: $secondary-green;
          font-size: 1.4rem;
          font-weight: 600;
        }

        .select-label {
          font-size: 0.9rem;
          padding-top: 0;
          padding-left: 1.6rem;
        }

        .select-all-label {
          color: $font-color-light;
          font-size: 0.8rem;
          padding-top: 0.6rem;
          text-align: right;
        }

        .check-container {
          padding-top: 0.6rem;
          padding-left: 1.9rem;
        }

        .ant-checkbox-indeterminate {
          .ant-checkbox-inner::after {
            width: 60%;
            height: 60%;
            top: 50%;
            left: 50%;
            background-color: #1dcf77;
          }
        }

        .ant-checkbox-inner {
          width: 26px;
          height: 26px;
          border: none;
          background-color: #d3d3d3;
          border-radius: 0.2rem;

          &::after {
            width: 9px;
            height: 20px;
            top: 46%;
            left: 22%;
            border: 3px solid #1dcf77;
            border-top: 0;
            border-left: 0;
          }
        }

        .ant-checkbox-checked::after {
          border: 1px solid #1dcf77;
        }

        .ant-divider {
          background-color: #dfdfdf;
        }

        .ant-divider-horizontal {
          margin: 24px 0 0 0;
        }

        .table-container {
          margin-top: 1rem;
        }
      }
    }

    .paginator {
      margin-top: 1.4rem;

      ul {
        position: relative;
        margin: auto;
      }
    }
  }

  .ant-pagination {
    text-align: center;
    float: unset;

    .ant-pagination-item {
      border: none;
      min-width: 25px;
      height: 25px;
      font-size: 0.7rem;
      line-height: 24px;
    }

    .ant-pagination-item-active {
      background-color: #09d56b;
      border-radius: 50%;

      a {
        color: white;
      }
    }

    .ant-pagination-item-link {
      border: none;
      padding-top: 0.2rem;

      span {
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: transparent;
    }
  }

  .date-container {
    margin-top: 0.5rem;

    .ant-picker {
      width: 100%;
      border-radius: 0.5rem;
    }
  }

  .btn-excel {
    background-color: #09d96d;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 0.5rem;

    &:hover {
      opacity: 0.5;
    }
  }

  .no-check {
    .ant-table-selection-column {
      display: none;
    }
  }

  .empty-bills {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-weight: 600;
  }

  .ant-spin-nested-loading>div>.ant-spin {
    height: 90vh;
    max-height: none;
  }

  .title-container {
    margin-top: 2rem;

    .title {
      display: inline-block;
      color: $font-color-title;
      font-size: 1rem;
      font-weight: 700;
    }

    .value {
      display: inline-block;
      color: $font-color-title;
      font-size: 1.4rem;
    }
  }

  .client-container {
    margin-top: 1.6rem;
    padding: 2%;

    .client {
      color: $secondary-green;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .bills-number {
      font-size: 1rem;
      font-weight: 700;
      text-align: right;
      margin-top: 0.4rem;

      span {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;

        &.green {
          color: $secondary-green;
        }

        &.blue {
          color: $primary-blue;
        }
      }
    }
  }

  .detail-container {
    text-align: center;
    margin-bottom: 0.5rem;

    button {
      border: none;
      box-shadow: none;
      color: #888888;

      &:after {
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
      }
    }
  }

  .disabled-investor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ccc6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 2;
    font-weight: bold;

    p {
      border-radius: 1rem;
      padding: 0.5rem;
      background-color: #cccc;
      margin-top: 2.5rem;
      font-size: 1rem;
    }

    .detail-container {
      position: absolute;
      bottom: 0;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      border-radius: 1rem;
      background-color: #cccc;

      button {
        border: none;
        box-shadow: none;
        color: #1890ff;
        background-color: transparent;

        &:after {
          -webkit-animation: none !important;
          -moz-animation: none !important;
          -o-animation: none !important;
          -ms-animation: none !important;
          animation: none !important;
        }
      }
    }
  }
}
