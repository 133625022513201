@import '~antd/dist/antd.css';

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
  margin: auto;
  
}

 .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
   border-bottom: 0.5rem solid gray;
 }

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar{
  background-color: #00C1ED;
  height: 0.3rem;
}


.ant-tabs-tab {
  font-size: 1.4rem;
  line-height: 4rem;
  color: #00C1ED;
}



.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #00C1ED;
  font-weight: bold;
}

.ant-picker-footer{
  display: flex;
  justify-content: center;
}
